const accordionFooter = () => {

    let public = () => {

        if (window.innerWidth < 992) {
            $("#site-footer .widget-title").on("click", function() {
                $(this).toggleClass("active");
            });
        }
    }

    let activeWebChat = () => {
        $("#site-footer .webchat-start a").removeAttr('href').addClass('webchat-start');
    }

    let init = () => {
        public();
        activeWebChat();
    }

    return {
       initAccordionFooter: init
    };

};
$(document).ready(accordionFooter().initAccordionFooter);