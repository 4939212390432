const headerMenu = () => {

    const headerId = `#masthead`,
            menuId = `#main-nav`,
            chatbotCta = `#webchat-lazyload`,
            stClickedClass = `clicked`,
            stFilterOpenClass = `filter-open`,
            menuPravalerClass = `${headerId} .menu_pravaler`,
            menuPrincipalClass = `${headerId} .menu-header-principal`,
            barInputSearch = `${headerId} input.bar-search-string`,
            clientWidth = window.innerWidth;


    const menuSectionsActive = () => {

        $(`${headerId} ${menuId} li.menu-item > a`).on(clientWidth <= 992 ? `click` : `mouseover`, 
        function() {
            $(`${menuPrincipalClass}`).css(`visibility`, `inherit`);

            if ($(this).parents(`.sub-menu`).length === 0)
                $(`${menuPrincipalClass} .background-submenu`).css(`height`, `0px`);
            

            if ($(this).next().hasClass(`sub-menu`)) {

                if (clientWidth <= 992) {
                    if (!$(this).next().find(`.title`).length)
                        $(this).next().prepend(`<li class="title">${ $(this).text() }</li>`);
                
                } else {
                    if (!$(this).parent('[class*=column]').length  && !$(this).next().find(`.title`).length)
                        $(this).next().prepend(`<li class="title">${ $(this).text() }</li>`);

                    if (!$(this).parent('[class*=column]').length) {
                        let bg_height = $(this).next().height() + 100;
                        $(`${menuPrincipalClass} .background-submenu`).css(`height`, `${bg_height}px`);
                    }
                }

                if (clientWidth <= 992) {

                    $(`${headerId} ${menuId} .title`).on(`click`, function() {
                        $(this)
                            .parent()
                            .parent()
                            .find(`.active`)
                            .removeClass(`active`)
                            .removeAttr(`style`);
                    });
                }

                return false;
            }
        });
    }

    const submenusNav = () => {

        $(`${headerId} ${menuId} li.menu-item a`).on(clientWidth <= 992 ? `click` : `mouseover`,
        function() {

            if ( $(this).next().hasClass(`sub-menu`) ) {

                if (clientWidth >= 992) {
                    $(`${menuPravalerClass}`).removeClass(stClickedClass).removeClass(stFilterOpenClass);
                    $(`${headerId}`).removeClass(stClickedClass);
                    $(`ul.navbar-nav li.menu-item-has-children`).removeClass(`clicado`);    
                }

                $(this)
                    .parent()
                    .siblings()
                    .find(`.active`)
                    .removeClass(`active`)
                    .removeAttr(`style`);

                $(this).addClass(`active`);
                $(this).next().addClass(`active`);
                $(`${menuPrincipalClass} .background-submenu`).addClass(`active`);

                return false;

            } else {
                $(this)
                    .parent()
                    .siblings()
                    .find(`.active`)
                    .removeClass(`active`)
                    .removeAttr(`style`);
            }
        });
    }

    const mouseleaveHeader = () => {
       
        $(`${headerId}`).mouseleave(function() {
            $(this)
                .find(`${menuId} .active`)
                .removeClass(`active`)
                .removeAttr(`style`);

            $(`${menuPravalerClass}`).removeClass(stClickedClass).removeClass(stFilterOpenClass);
            $(`${headerId}`).removeClass(stClickedClass);
            $(`ul.navbar-nav li.menu-item-has-children`).removeClass(`clicado`);
        });
    }

    const eventOpenBarSearch = () => {

        $(`${barInputSearch}`).on(`focus`, function() {
            $(`${menuPravalerClass}`).addClass(`${stClickedClass}`);
            $(`${headerId}`).addClass(`${stClickedClass}`);

            $(`${menuPrincipalClass}`).css(`visibility`, `hidden`);
            $(`${headerId} span.close-filter`).fadeIn();
            $(`${headerId} section.search-filter`).slideDown(500, function(){});
            $(`${menuPravalerClass}`).addClass(`${stFilterOpenClass}`);
        });
    }

    const eventCloseBarSearch = () => {

        $(`${headerId} span.close-filter`).on("click", function() {

            $(`${menuPrincipalClass}`).css(`visibility`, `visible`);

            $(`
                ${headerId} section.search-filter, 
                ${headerId} section.search-result > .no-result,
                ${headerId} section.search-filter .suggested-result > section
            `).css(`display`, `none`);

            $(`
                ${headerId} section.search-filter .filter-see-all, 
                ${headerId} section.search-filter .links-populares    
            `).removeAttr(`style`);

            $(`${barInputSearch}`).val(``);

            $(`${headerId} section.search-filter .suggested-result > section`).slideUp(500, function() {});
            $(`${menuPravalerClass}`).removeClass(`${stFilterOpenClass}`);
            $(`${barInputSearch}`).blur();
            $(this).css(`display`, `none`);
        });
    }

    const navbarTogglerMobile = () => {

        let menuAnimate = false;

        $(`${menuPravalerClass} .navbar-toggler, 
            ${menuPravalerClass} .bt-search, 
            ${menuPravalerClass} .lightbox-search
        `).on(`click`, function(e) {

            $(`${menuPravalerClass}`).removeClass(stFilterOpenClass);
            e.stopPropagation();

            if(!menuAnimate) {
                menuAnimate = true;
                $(`${barInputSearch}`).val(``);

                setTimeout( function() { 
                    menuAnimate = false;
                }, 1700);

                if( clientWidth < 992 ) {

                    $(`${menuId}`).scrollTop(0);
                    $(`${chatbotCta}`).fadeOut();

                    if( $(this).hasClass(`bt-search`) ) {
                        $(`${menuPrincipalClass}`).css(`visibility`, `hidden`);

                        setTimeout(function() {
                            $(`${barInputSearch}`).focus();
                        }, 570);

                        $(`${headerId} span.close-filter`).css(`display`, `none`);
                        $(`${headerId} section.search-filter`).addClass(`active`);

                    } else {
                        $(`${menuPrincipalClass}`).css(`visibility`, `visible`);

                        $(`
                            ${headerId} section.search-filter .filter-see-all, 
                            ${headerId} section.search-filter .links-populares    
                        `).removeAttr(`style`);

                        $(`
                            ${headerId} section.search-filter, 
                            ${headerId} section.search-filter .suggested-result > section
                         `).css(`display`, `none`);
                    }
                }

                if( $(`${menuPravalerClass}`).hasClass(stClickedClass) ) {

                    $(`${chatbotCta}`).removeAttr(`style`);
                    $(`${headerId} span.close-filter`).css(`display`, `none`);
                    $(`${menuPravalerClass}`).removeClass(stClickedClass).removeClass(stFilterOpenClass);

                    setTimeout(function() {

                        $(`${headerId}`).removeClass(stClickedClass);
                        setTimeout(function() {
                            $(`ul.navbar-nav li.menu-item-has-children`).removeClass(`clicado`);
                        }, 870);
                    },570);

                } else {
                    $(`${headerId}`)
                        .find(`${menuId} .active`)
                        .removeClass(`active`)
                        .removeAttr(`style`);

                    $(`${menuPravalerClass}`).addClass(stClickedClass);
                    $(`${headerId}`).addClass(stClickedClass);
                }
            }
        });
    }

    let init = () => {
        menuSectionsActive();
        submenusNav();
        mouseleaveHeader();
        eventOpenBarSearch();
        eventCloseBarSearch();
        navbarTogglerMobile();
    }

    return {
       init: init
    };

};
$(document).ready(headerMenu().init);